<template>
  <v-container
    fluid
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-card
      :style="`height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}; overflow-x: hidden;`"
    >
      <div class="text-center pt-4">
        <h2>
          {{ $t('expressions|activate_lavvira_account') }}
        </h2>
        <h4>
          {{ $t('buy|plans_start') }}
        </h4>
      </div>
      <div
        v-if="!company"
        class="text-center mx-4"
      >
        <span
          style="font-size: 16px;"
        >
          {{ $t('expressions|to_use_collaboration_module_please_click') }}
          <a
            class="link"
            @click="goToCreateCompany()"
          >
            {{ $t('common|here') }}
          </a>
          {{ $t('expressions|to_create_company_profile') }}.
        </span>
      </div>
      <v-row
        justify="center"
        class="mt-2"
      >
        <v-col
          xs="12"
          md="2"
          lg="3"
        >
          <v-card
            elevation="5"
            outlined
            class="mb-6"
            :min-height="($vuetify.breakpoint.height - 310).toString() + 'px'"
            min-width="400"
          >
            <v-card-title
              class="justify-center"
            >
              <h3
                style="color: #234e79;"
              >
                {{ $t('account|single_lavvira_account') }}
              </h3>
            </v-card-title>
            <v-card-text
              class="text-center pt-16"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="account.subscriptionStatus === 'paid' || account.subscriptionStatus === 'company paid' ? on : null"
                  >
                    <v-btn
                      color="primary"
                      :disabled="account.subscriptionStatus === 'paid' || account.subscriptionStatus === 'company paid'"
                      @click="upgradeAccount"
                    >
                      {{ $t('actions|subscribe') }}
                    </v-btn>
                  </div>
                </template>
                <span
                  v-if="account.subscriptionStatus === 'paid' || account.subscriptionStatus === 'company paid'"
                >
                  {{ $t('expressions|account_is_currently_active') }}
                </span>
              </v-tooltip>
              <div class="mt-6">
                <h4>{{ account && account.subscriptionStatus === 'beta' ? '0€' : cfg.preices.singleAccountPreis + '€' }}/{{ $t('common|month') }}</h4>
                <span v-if="account && account.subscriptionStatus === 'beta'">{{ $t('expressions|for_beta_users') }}</span>
                <span v-else>{{ $t('members|billed_montly') }}</span>
              </div>
            </v-card-text>
            <div class="element-position ml-4">
              <v-divider class="mb-4" />
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ $t('expressions|all_core_features') }}</span><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ cfg.storageData.singleStorage }} {{ $t('common|storage') }}</span><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ $t('common|email_support') }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import cfg from '@/config'
import autobahn from 'autobahn'

export default {
  components: { StripeCheckout },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      loading: false,
      message: '',
      cfg

    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    goToCreateCompany () {
      this.$router.push('/create-company')
    },
    upgradeAccount () {
      console.log('PAYMENT')
      this.message = this.$t('message|proceed_to_checkout')
      this.loading = true
      let result = null
      let n = 0
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (account) => {
            const _res = await session.call('lavvira.stripe_controller.create_payment_session',
              [
                account._id,
                'account',
                1
              ])
            return _res
          }
          while (n <= 5) {
            result = await makeSessionCall(this.account)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.log(e, 'WAMP ERROR')
      }
    }
  }
}
</script>

<style scoped>
.element-position {
  position: absolute;
  width: 90%;
  bottom: 30px;
}
.link {
 color: #234E79;
 text-decoration: none;
 cursor: pointer;
 font-weight: bold;
}
.link:hover {
  color: black;
}
</style>
